import React from "react"
import window from "global"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Fade from 'react-reveal/Fade';

function About() {

  const data = useStaticQuery(graphql`
   query aboutQuery {
    BackgroundImage: file(relativePath: { eq: "OVER ONS/LOSSE AFBEELDINGEN/Background_overons.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
      }
    tools: file(relativePath: { eq: "HOME/LOSSE AFBEELDINGEN/Achtergrond_materiaal.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    iconRibbon: file(relativePath: { eq: "OVER ONS/LOSSE AFBEELDINGEN/Icon_deskundigheid.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    iconPig: file(relativePath: { eq: "OVER ONS/LOSSE AFBEELDINGEN/Icon_scherp geprijsd.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    iconWrite: file(relativePath: { eq: "OVER ONS/LOSSE AFBEELDINGEN/Offerte.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    iconHead: file(relativePath: { eq: "OVER ONS/LOSSE AFBEELDINGEN/IMG_persoonlijk.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    iconCheck: file(relativePath: { eq: "OVER ONS/LOSSE AFBEELDINGEN/IMG_geen zorgen.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    iconSafe: file(relativePath: { eq: "OVER ONS/LOSSE AFBEELDINGEN/IMG_veiligheid.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
  `)

  const [hasMounted, setHasMounted] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)
  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  React.useEffect(() => {
    setHasMounted(true)
  }, [width])

  if (!hasMounted) {
    return null
  }

  let coverText = (
    <Col
      className={"head-text"}
      style={{ color: "white", marginTop: "20vh" }}
      md={{ span: 10, offset: 1 }}>
      <Fade top delay="500" duration="2000"><h3>Over ons</h3></Fade>
      <Fade left duration="2000"><h1 style={{ fontSize: "4em" }}>Alders Vastgoedonderhoud,<br></br> vakmanschap waar u op kunt bouwen</h1></Fade>
      <Fade bottom delay="750" duration="1500"><h2 className={"normal-text"}>Alders Vastgoedonderhoud, uw betrouwbare partner voor dakwerkzaamheden, 
      schilderwerk, stucwerk en timmerwerkzaamheden. Bent u op zoek naar een echte professional die het vak verstaat en al vele projecten heeft afgerond? 
      Alders Vastgoedonderhoud is een echte specialist in het realiseren van kleine en grote projecten.</h2>
      </Fade>
    </Col>
  )
  if (width <= 1250) {
    coverText = (
      <Col
      className={"head-text"}
      style={{ color: "white", marginTop: "-70vh", zIndex: -1 }}
      md={{ span: 10, offset: 1 }}>
      <Fade top delay="500" duration="2000"><h4>Over ons</h4></Fade>
      <Fade left duration="2000"><h2>Alders Vastgoed-onderhoud,<br></br> vakmanschap waar u op kunt bouwen</h2></Fade>
      <Fade bottom delay="750" duration="1500"><h4 className={"normal-text"}>Alders Vastgoedonderhoud, uw betrouwbare partner voor dakwerkzaamheden, 
      schilderwerk, stucwerk en timmerwerkzaamheden. Bent u op zoek naar een echte professional die het vak verstaat en al vele projecten heeft afgerond? 
      Alders Vastgoedonderhoud is een echte specialist in het realiseren van kleine en grote projecten.</h4></Fade>
    </Col>
    )
  }

  if (width <= 600) {
    coverText = (
      <Col
      className={"head-text"}
      style={{ color: "white", marginTop: "-75vh", zIndex: -1 }}
      md={{ span: 10, offset: 1 }}>
      <Fade top delay="500" duration="2000"><h5>Over ons</h5></Fade>
      <Fade left duration="2000"><h4>Alders Vastgoed-onderhoud,<br></br> vakmanschap waar u op kunt bouwen</h4></Fade>
      <Fade bottom delay="750" duration="1500"><p className={"normal-text"}>Bent u op zoek naar een echte professional die het vak verstaat en al vele projecten heeft afgerond?
      Alders Vastgoedonderhoud is een echte specialist in het realiseren van kleine en grote projecten.</p></Fade>
    </Col>
    )
  }

  return (
    <>
      <SEO title="Over ons" />
      <Layout width={width} backgroundImage={data.BackgroundImage.childImageSharp.fluid} coverText={coverText} backgroundImageStyles={{ backgroundPosition: "left", height: "100vh", backgroundColor: "black" }}>
        <Container>
          <Row>
            <Col className="head-text" style={{color: "#1F2D53", textAlign: "center", marginTop: "10%"}}>
            <h2>WAAROM KIEZEN VOOR ALDERS VASTGOED&shy;ONDERHOUD?</h2>
            <br></br>
            <h5>Wij vinden het belangrijk dat u als klant tevreden bent.<br></br>
            Daarom garandeert Alders Vastgoedonderhoud 100% klanttevredenheid op z’n werkzaamheden.</h5>
            </Col>
          </Row>
          <Row className="normal-text my-5" style={{color: "#1F2D53", textAlign: "center", justifyContent: "space-between"}}>
            <Col xs={12} xl={3} className="mt-0 mt-xl-5">
              <Fade left>
                <Image className="mb-3" fixed={data.iconRibbon.childImageSharp.fixed}/>
                <p className="head-text">Deskundigheid</p>
                <p>Door ruime ervaring is Alders Vastgoedonderhoud een echte vakspecialist.</p>
              </Fade>
            </Col>
            <Col xs={12} xl={3} className="mt-5">
              <Fade bottom>
                <Image className="mb-3" fixed={data.iconPig.childImageSharp.fixed}/>
                <p className="head-text">Scherp geprijsd</p>
                <p>Onze materialen zijn van hoge kwaliteit en scherp geprijsd.</p>
              </Fade>
            </Col>
            <Col xs={12} xl={3} className="mt-5">
              <Fade right>
                <Image className="mb-3 mr-n4" fixed={data.iconWrite.childImageSharp.fixed}/>
                <p className="head-text">Offerte binnen 3 werkdagen</p>
                <p>U ontvangt altijd binnen 3 werkdagen een offerte op maat.</p>
              </Fade>
            </Col>
          </Row>
          <Row className="normal-text my-5" style={{color: "#1F2D53", textAlign: "center", justifyContent: "space-between"}}>
            <Col xs={12} xl={3} className="mt-0 mt-xl-5">
              <Fade left>
                <Image className="mb-3" fixed={data.iconHead.childImageSharp.fixed}/>
                <p className="head-text">Persoonlijk</p>
                <p>Wij luisteren naar uw wensen en behoeften en geven u het beste advies.</p>
              </Fade>
            </Col>
            <Col xs={12} xl={3} className="mt-5">
              <Fade bottom>
                <Image className="mb-3" fixed={data.iconCheck.childImageSharp.fixed}/>
                <p className="head-text">Geen zorgen</p>
                <p>U wordt volledig ontzorgd door Alders Vastgoedonderhoud en wij denken met u mee in oplossingen.</p>
              </Fade>
            </Col>
            <Col xs={12} xl={3} className="mt-5">
              <Fade right>
                <Image className="mb-3" fixed={data.iconSafe.childImageSharp.fixed}/>
                <p className="head-text">Veiligheid</p>
                <p>Onze materialen zijn van hoge kwaliteit en inbraakveilig.</p>
              </Fade>
            </Col>
          </Row>
        </Container>
        <Container fluid style={{color: "white", backgroundColor: "#1F2D53"}}>
          <Row>
            <Col lg={{span: 11, offset: 1}} className="head-text my-5">
              <Fade left>
                <h2>SPECIALITEIT VAN ALDERS VASTGOED&shy;ONDERHOUD</h2>
                <ul>
                  <li>Dakwerkzaamheden</li>
                  <li>Schilderwerk</li>
                  <li>Stucwerk</li>
                  <li>Timmerwerkzaamheden</li>
                </ul>
              </Fade>
            </Col>
          </Row>
        </Container>
        <BackgroundImage className="parallax" fluid={data.tools.childImageSharp.fluid}>
          <Container fluid className="head-text" style={{height: "80vh", textAlign: "center", color: "white"}}>
            <Row style={{paddingTop: "15vh", alignItems:"center"}}>
              <Col xs={12}>
                <h1>NEEM CONTACT MET ONS OP</h1>
                <h4>Wij zijn snel en makkelijk te bereiken.</h4>
              </Col>
            </Row>
            <Row style={{paddingTop: "10vw"}}>
              <Col md={{span: 6}} xl={{span: 3, offset: 3}}>
                <Button href="tel:+31-629543404" rel="nofollow" className={"head-text no-underline mt-3"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0}}>BEL ONS DIRECT</Button>
              </Col>
              <Col md={{span: 6}} xl={3}>
                <Link rel="nofollow" to="/quotation/" className={"head-text no-underline mt-3 btn"} style={{minWidth: "300px", backgroundColor:"#182F55", borderRadius: 0, display:"inline-block", marginTop:"-6px", border: 0, color:"white"}}>OFFERTE AANVRAGEN</Link>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>
      </Layout>
    </>
  )
}

export default About
